var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pdf-print-ready" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "" } },
        [
          _c(
            "v-toolbar-title",
            [
              _c("v-icon", { attrs: { large: "", left: "" } }, [
                _vm._v("mdi-home")
              ]),
              _vm._v(" " + _vm._s(_vm.$t("rental-reference-form.title")) + " ")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "subtitle-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("common.to")) +
                          ": " +
                          _vm._s(_vm.contact_name) +
                          " "
                      )
                    ]),
                    _c("p", { staticClass: "subtitle-1" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("rental-reference-form.contact_msg")) +
                          " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", [
                    _c("p", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.applicant_name) + " ")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("p", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(" DOB: "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("common.rent_amt")) + ": "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("common.move_in")) + ": "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("common.move_out")) + ": ")
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "9" } }, [
                    _c("p", { staticClass: "subtitle-1 font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.applicant_birthdate) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.listed_rent_amount) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.listed_move_in) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.listed_move_out) + " ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.listed_rent_amount", {
                              listed_rent_amount: _vm.listed_rent_amount
                            })
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c("v-text-field", {
                        attrs: { prefix: "$", type: "number" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("rental-reference-form.lease_type"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: {
                              value: "monthToMonth",
                              label: _vm.$t(
                                "rental-reference-form.lease_type_values.monthToMonth"
                              )
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "oneYear",
                              label: _vm.$t(
                                "rental-reference-form.lease_type_values.oneYear"
                              )
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              value: "multiYear",
                              label: _vm.$t(
                                "rental-reference-form.lease_type_values.multiYear"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.are_dates_correct")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "dateMenu1",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "common.move_in"
                                                    ),
                                                    "prepend-icon":
                                                      "mdi-calendar"
                                                  },
                                                  model: {
                                                    value: _vm.listed_move_in,
                                                    callback: function($$v) {
                                                      _vm.listed_move_in = $$v
                                                    },
                                                    expression: "listed_move_in"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "common.move_out"
                                                    ),
                                                    "prepend-icon":
                                                      "mdi-calendar"
                                                  },
                                                  model: {
                                                    value: _vm.listed_move_out,
                                                    callback: function($$v) {
                                                      _vm.listed_move_out = $$v
                                                    },
                                                    expression:
                                                      "listed_move_out"
                                                  }
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-radio-group",
                                { attrs: { row: "" } },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "rental-reference-form.are_dates_correct_values.yes"
                                      ),
                                      value: true
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t(
                                        "rental-reference-form.are_dates_correct_values.no"
                                      ),
                                      value: false
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("rental-reference-form.gave_notice"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.reference_released")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.contact_state === "OR"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "rental-reference-form.has_late_payment"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        { attrs: { row: "" } },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.yes"),
                                              value: true
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.no"),
                                              value: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: _vm.$t("common.how_many")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "rental-reference-form.has_three_day_notice"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        { attrs: { row: "" } },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.yes"),
                                              value: true
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.no"),
                                              value: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: _vm.$t("common.how_many")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "rental-reference-form.has_nsf_check"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        { attrs: { row: "" } },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.yes"),
                                              value: true
                                            }
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: _vm.$t("common.no"),
                                              value: false
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          label: _vm.$t("common.how_many")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.owes_rent_amount")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c("v-text-field", {
                        attrs: { type: "number", prefix: "$" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.show_covid_fields
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "rental-reference-form.covid_deferred_amount"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: { type: "number", prefix: "$" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.contact_state == "OR" && _vm.show_covid_fields
                                ? _c("br")
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "common.explain_here"
                                          ),
                                          rows: "1",
                                          "auto-grow": ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "rental-reference-form.covid_past_due_amount"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: { type: "number", prefix: "$" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "my-0",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "common.explain_here"
                                          ),
                                          rows: "1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contact_state !== "OR" && _vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state !== "OR" && _vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("rental-reference-form.has_violation"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-radio-group",
                                { attrs: { row: "" } },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t("common.yes"),
                                      value: true
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t("common.no"),
                                      value: false
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder: _vm.$t("common.explain_here"),
                                  rows: "1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.owes_for_damage")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "v-radio-group",
                                { attrs: { row: "" } },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t("common.yes"),
                                      value: true
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: _vm.$t("common.no"),
                                      value: false
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: { type: "number", prefix: "$" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "my-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder: _vm.$t("common.explain_here"),
                                  rows: "1"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.contact_state !== "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state !== "OR" && !_vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "rental-reference-form.signed_rental_agreement"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "rental-reference-form.has_unauthorized_tenants"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "rental-reference-form.has_unauthorized_pets"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(_vm._s(_vm.$t("rental-reference-form.has_pet")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(_vm.$t("rental-reference-form.was_evicted"))
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.was_deposit_refunded")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.yes"), value: true }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.no"), value: false }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.contact_state == "OR" && _vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _vm.contact_state == "OR" && _vm.show_covid_fields
                ? _c("br")
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-subheader", { staticClass: "subtitle-1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("rental-reference-form.would_rent_to_again")
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-radio-group",
                        { attrs: { row: "" } },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("common.yes"),
                              value: "true"
                            }
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: _vm.$t("common.no"),
                              value: "false"
                            }
                          }),
                          _c("v-radio", {
                            attrs: { label: _vm.$t("common.na"), value: "N/A" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-0", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-textarea", {
                        staticClass: "ml-4",
                        attrs: {
                          outlined: "",
                          label: _vm.$t("common.additional_comments"),
                          rows: "2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }