<template>
    <div id="pdf-print-ready">

        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                <v-icon large left>mdi-home</v-icon>
                {{ $t("rental-reference-form.title") }}
            </v-toolbar-title>
        </v-toolbar>

        <v-card outlined>
            <v-card-text>
                <v-row no-gutters class="my-0">
                    <v-col>
                        <p class="subtitle-1">
                           {{$t("common.to")}}: {{contact_name}}
                        </p>
                        <p class="subtitle-1">
                            {{$t("rental-reference-form.contact_msg")}}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col>
                        <p class="subtitle-1 font-weight-bold">
                            {{applicant_name}}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="3">
                        <p class="subtitle-1 font-weight-bold">
                            DOB:
                            <br/>
                            {{$t("common.rent_amt")}}:
                            <br/>
                            {{$t("common.move_in")}}:
                            <br/>
                            {{$t("common.move_out")}}:
                        </p>
                    </v-col>
                    <v-col cols="9">
                            <p class="subtitle-1 font-weight-bold">
                            {{applicant_birthdate}}
                            <br/>
                            {{listed_rent_amount}}
                            <br/>
                            {{listed_move_in}}
                            <br/>
                            {{listed_move_out}}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card-text>
            <v-form ref="form">
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.listed_rent_amount", { listed_rent_amount })
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field
                            prefix="$"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.lease_type")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio
                                value="monthToMonth"
                                :label="$t('rental-reference-form.lease_type_values.monthToMonth')"
                            ></v-radio>
                            <v-radio
                                value="oneYear"
                                :label="$t('rental-reference-form.lease_type_values.oneYear')"
                            ></v-radio>
                            <v-radio
                                value="multiYear"
                                :label="$t('rental-reference-form.lease_type_values.multiYear')"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.are_dates_correct")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-row no-gutters class="my-0">
                            <v-col cols="6">
                                <v-menu
                                    ref="dateMenu1"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="$t('common.move_in')"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="listed_move_in"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :label="$t('common.move_out')"
                                            prepend-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="listed_move_out"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        no-title
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0">
                            <v-col cols="12">
                                <v-radio-group
                                    row
                                >
                                    <v-radio
                                        :label="$t('rental-reference-form.are_dates_correct_values.yes')"
                                        v-bind:value="true"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('rental-reference-form.are_dates_correct_values.no')"
                                        v-bind:value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.gave_notice")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.reference_released")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <div v-if="contact_state === 'OR'">
                    <v-row no-gutters class="my-0">
                        <v-col cols="5">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_late_payment")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row no-gutters class="my-0">
                                <v-col cols="4">
                                    <v-radio-group
                                        row
                                    >
                                        <v-radio
                                            :label="$t('common.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('common.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field
                                        type="number"
                                        :label="$t('common.how_many')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-0">
                        <v-col cols="5">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_three_day_notice")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row no-gutters class="my-0">
                                <v-col cols="4">
                                    <v-radio-group
                                        row
                                    >
                                        <v-radio
                                            :label="$t('common.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('common.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field
                                        type="number"
                                        :label="$t('common.how_many')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-0">
                        <v-col cols="5">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.has_nsf_check")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row no-gutters class="my-0">
                                <v-col cols="4">
                                    <v-radio-group
                                        row
                                    >
                                        <v-radio
                                            :label="$t('common.yes')"
                                            v-bind:value="true"
                                        ></v-radio>
                                        <v-radio
                                            :label="$t('common.no')"
                                            v-bind:value="false"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field
                                        type="number"
                                        :label="$t('common.how_many')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.owes_rent_amount")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field
                            type="number"
                            prefix="$"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <div v-if="show_covid_fields">
                    <v-row no-gutters class="my-0">
                        <v-col cols="5">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.covid_deferred_amount")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row no-gutters class="my-0">
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        prefix="$"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <br v-if="contact_state == 'OR' && show_covid_fields"/>

                            <v-row no-gutters class="my-0">
                                <v-col cols="12">
                                    <v-textarea
                                        :placeholder="$t('common.explain_here')"
                                        rows="1"
                                        auto-grow
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>


                    <v-row no-gutters class="my-0">
                        <v-col cols="5">
                            <v-subheader class="subtitle-1">{{
                                $t("rental-reference-form.covid_past_due_amount")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-row no-gutters class="my-0">
                                <v-col>
                                    <v-text-field
                                        type="number"
                                        prefix="$"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="my-0">
                                <v-col cols="12">
                                    <v-textarea
                                        :placeholder="$t('common.explain_here')"
                                        rows="1"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>

                <br v-if="contact_state !== 'OR' && show_covid_fields"/>
                <br v-if="contact_state !== 'OR' && show_covid_fields"/>

                <br v-if="contact_state == 'OR' && !show_covid_fields"/>
                <br v-if="contact_state == 'OR' && !show_covid_fields"/>
                <br v-if="contact_state == 'OR' && !show_covid_fields"/>
                <br v-if="contact_state == 'OR' && !show_covid_fields"/>
                <br v-if="contact_state == 'OR' && !show_covid_fields"/>
                <br v-if="contact_state == 'OR' && !show_covid_fields"/>

                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.has_violation")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-row no-gutters class="my-0">
                            <v-col>
                                <v-radio-group
                                    row
                                >
                                    <v-radio
                                        :label="$t('common.yes')"
                                        v-bind:value="true"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('common.no')"
                                        v-bind:value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0">
                            <v-col cols="12">
                                <v-textarea
                                    :placeholder="$t('common.explain_here')"
                                    rows="1"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.owes_for_damage")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-row no-gutters class="my-0">
                            <v-col cols="4">
                                <v-radio-group row>
                                    <v-radio
                                        :label="$t('common.yes')"
                                        v-bind:value="true"
                                    ></v-radio>
                                    <v-radio
                                        :label="$t('common.no')"
                                        v-bind:value="false"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="8">
                                <v-text-field
                                    type="number"
                                    prefix="$"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-0">
                            <v-col cols="12">
                                <v-textarea
                                    :placeholder="$t('common.explain_here')"
                                    rows="1"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <br v-if="contact_state !== 'OR' && !show_covid_fields"/>
                <br v-if="contact_state !== 'OR' && !show_covid_fields"/>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.signed_rental_agreement")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group row>
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.has_unauthorized_tenants")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.has_unauthorized_pets")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.has_pet")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group row>
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.was_evicted")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.was_deposit_refunded")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group
                            row
                        >
                            <v-radio :label="$t('common.yes')" v-bind:value="true"></v-radio>
                            <v-radio :label="$t('common.no')" v-bind:value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <br v-if="contact_state == 'OR' && show_covid_fields"/>
                <br v-if="contact_state == 'OR' && show_covid_fields"/>
                <v-row no-gutters class="my-0">
                    <v-col cols="5">
                        <v-subheader class="subtitle-1">{{
                            $t("rental-reference-form.would_rent_to_again")
                        }}</v-subheader>
                    </v-col>
                    <v-col cols="7">
                        <v-radio-group row>
                            <v-radio :label="$t('common.yes')" value="true"></v-radio>
                            <v-radio :label="$t('common.no')" value="false"></v-radio>
                            <v-radio :label="$t('common.na')" value="N/A"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-0">
                    <v-col cols="12">
                        <v-textarea
                            outlined
                            class="ml-4"
                            :label="$t('common.additional_comments')"
                            rows="2"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
export default {
    name: "rental-reference-pdf",
    props: {
        reference: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            // API data
            applicant_name: this.reference.applicant.name,
            applicant_birthdate: this.reference.applicant.birthdate,
            contact_name: this.reference.reference.contact.name,
            listed_rent_amount: this.reference.reference.address.monthly_payment,
            listed_move_in: this.reference.reference.address.move_in_date,
            listed_move_out: this.reference.reference.address.move_out_date,
            contact_state: this.reference.reference.contact.state, 
            show_covid_fields: this.reference.meta.show_covid_fields,

            // This is to help get the spacing correct so fields don't get cut off
            // OR,covid
            
            // FALSE,FALSE
            // <br v-if="contact_state !== 'OR' && !show_covid_fields"/>
            // contact_state: "MT", 
            // show_covid_fields: false,

            // // TRUE,FALSE
            // <br v-if="contact_state == 'OR' && !show_covid_fields"/>
            // contact_state: "OR", 
            // show_covid_fields: false,

            // // FALSE,TRUE
            // <br v-if="contact_state !== 'OR' && show_covid_fields"/>
            // contact_state: "MT", 
            // show_covid_fields: true,

            // // TRUE,TRUE
            // <br v-if="contact_state == 'OR' && show_covid_fields"/>
            // contact_state: "OR", 
            // show_covid_fields: true,
        };
    }
};
</script>